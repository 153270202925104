// src/regions.js

const regions = {
    "AF": "South Asia",
    "BD": "South Asia",
    "BT": "South Asia",
    "IN": "South Asia",
    "MV": "South Asia",
    "NP": "South Asia",
    "PK": "South Asia",
    "LK": "South Asia",
    "AM": "West Asia",
    "AZ": "West Asia",
    "BH": "West Asia",
    "CY": "West Asia",
    "GE": "West Asia",
    "IQ": "West Asia",
    "IL": "West Asia",
    "JO": "West Asia",
    "KW": "West Asia",
    "LB": "West Asia",
    "OM": "West Asia",
    "PS": "West Asia",
    "QA": "West Asia",
    "SA": "West Asia",
    "SY": "West Asia",
    "TR": "West Asia",
    "AE": "West Asia",
    "YE": "West Asia",
    "CN": "East Asia",
    "HK": "East Asia",
    "JP": "East Asia",
    "MO": "East Asia",
    "MN": "East Asia",
    "KP": "East Asia",
    "KR": "East Asia",
    "TW": "East Asia",
    "BN": "Southeast Asia",
    "KH": "Southeast Asia",
    "TL": "Southeast Asia",
    "ID": "Southeast Asia",
    "LA": "Southeast Asia",
    "MY": "Southeast Asia",
    "MM": "Southeast Asia",
    "PH": "Southeast Asia",
    "SG": "Southeast Asia",
    "TH": "Southeast Asia",
    "VN": "Southeast Asia",
    "KZ": "Central Asia",
    "KG": "Central Asia",
    "TJ": "Central Asia",
    "TM": "Central Asia",
    "UZ": "Central Asia"
  };
  
  export default regions;
  